<template>
    <div id='activateChart'>
        <h2>סיימת לבחור שאלות?</h2>
        <div id='chartModes'>
            <div>
                <h3>{{ $parse('לחץ', 'לחצי', 'לחצו') }} לצפייה בגרף</h3>
                <button class='material-icons-round' @click='$emit("update:chartMode", "line-chart")'>insights</button>
            </div>
            <div class='modeOr'>או</div>
            <div>
                <h3>{{ $parse('לחץ', 'לחצי', 'לחצו') }} לצפייה בטבלה השוואתית</h3>
                <button class='material-icons-round' @click='$emit("update:chartMode", "comparison-table")'>table_chart</button>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    emits: ["update:chartMode"]
});
</script>

<style lang="scss" scoped>
    #activateChart {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;

        & #chartModes {
            display: flex;
            width: 40rem;
            justify-content: space-between;
            margin-top: 2rem;

            & > div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                
                &:not(.modeOr) {
                    width: 15rem;
                }
            }

            & .modeOr {
                color: #777;
                &:before, &:after {
                    content: '';
                    flex: 1;
                    width: 1px;
                    background-color: #ddd;
                    display: block;
                }
            }

            & button {
                cursor: pointer;
            }
        }

        & h2 {
            font-size: 1.8rem;
            background-color: transparent;
            margin-top: -2rem;
        }

        & h3 {
            font-size: 1.2rem;
            color: #777;
        }

        & button {
            margin-top: 1.5rem;
            @include shadow;
            border-radius: $corner;
            font-size: 3rem;
            padding: .5rem 2rem;
            background-color: $swatchB;

            &:hover{
                background-color: $hoverB;
            }
        }
    }
</style>