<template>
   <div id='chartWrapper'>
        <div>
            <div id='chartLoading' v-if='!loaded'>טוען...</div>
            <!-- <span class='material-icons-outlined' @click='chartHandler' :style='{ position: "absolute", right: ".2rem", top: ".2rem", cursor: "pointer" }'>
                refresh
            </span> -->
            <template v-if='loaded'>
                <div class='chartLegend'>
                    <div v-for='(dataset, index) in datasets' @click='toggleDataset(index)' :key='dataset.label'>
                        <div class='chartLegendBullet' :style='{ borderColor: dataset.borderColor, backgroundColor: !hiddenDatasets[index] ? dataset.borderColor : undefined }' />
                        {{ dataset.label }}
                    </div>
                </div>
                <div class='chartTooltip card' :style='tooltip.style'>
                    {{ tooltip.text }}
                </div>
                <div class='selectionIndicator' :class='{ hasSelection: Boolean(currentQuestion) }' :style='currentQuestion?.style'>
                    <span class='material-icons'>my_location</span>
                </div>
            </template>
            <canvas dir='rtl' height='116' ref='chart' v-show='loaded' />
        </div>
    </div>
    <chart-details v-bind='{ datasets, relevantDates }' />
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/runtime-core";
import Chart, { ActiveElement } from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
// @ts-ignore
import { auditTime, filter, Subject, Subscription } from 'rxjs';
import { setCurrentQuestion, jumpToDate } from './module';

import ChartDetails from "./ChartDetails.vue";
import { SharedChartDataset } from "@/ts/state/ViewQuestionnaireState";

let chart: Chart = {} as Chart;
const mouseMoveObserver = new Subject<Event>();
let subscription = {} as Subscription;

type data = {
    tooltip: { style: { [key: string]: string | number }, text: string },
    hiddenDatasets: { [key: number]: boolean },
    loaded: boolean
}

const colors = ['#2b225c', '#6b276c', '#a62a6b', '#d73e5c', '#f76543', '#ff971d', '#f5cb00', '#cfff25'];
const getColor = (index: number) => colors[index < colors.length ? index : index - (Math.floor(index / colors.length) * colors.length)];

export default defineComponent({
    props: {
        relevantDates: { type: Array as PropType<string[]>, required: true }
    },
    components: { ChartDetails },
    async beforeMount(){       
        subscription = mouseMoveObserver.pipe(
            auditTime(100),
            filter((event: Event) => event?.type === 'mousemove')
        ).subscribe((event: Event) => this.onChartMouseMove(event));
    },
    mounted(){
        this.chartHandler();
    },
    beforeUnmount: () => {
        subscription.unsubscribe?.();
        chart.destroy?.();
        chart = {} as Chart;
        setCurrentQuestion();
    },
    data: (): data => ({
        tooltip: { style: { opacity: 0 }, text: '' },
        hiddenDatasets: {},
        loaded: false
    }),
    computed: {
        currentQuestion(){
            return this.$store.state.viewQuestionnaire?.currentQuestion;
        },
        datasets(){
            const { questions, responsesById, responsesByDate } = this.$store.state.viewQuestionnaire ?? {};
            const { relevantDates } = this;
            const datasets: SharedChartDataset[] = [];

            for(const path in questions){
                const node = questions[path];
                if(node.selected && responsesById?.[node.id!]){
                    const question = this.$store.state.chooser!.questions.find(q => q.id.toString() === node.id);
                    if(question?.type?.startsWith('radio')){
                        const color = getColor(datasets.length);
                        datasets.push({
                            id: node.path!,
                            label: this.$parse(question.text),
                            data: this.relevantDates.map((date: string) => {
                                const response = responsesByDate![date][node.path!.split('/').slice(1).join('/')];
                                return response?.value || response?.value === 0 ? parseInt(response.value as string) + 1 : 0;
                            }),
                            fill: false,
                            showLine: question.type === 'radioContinuous',
                            borderColor: color,
                            borderWidth: 2,
                            tension: 0.1,
                            pointBackgroundColor: question.type !== 'radioContinuous' ? color : 'transparent',
                            pointHitRadius: relevantDates.length < 50 ? 12 : 6,
                            pointRadius: relevantDates.length < 50 ? 4 : 3,
                            pointHoverRadius: relevantDates.length < 50 ? 6 : 5
                        })
                    }
                }
            }

            return datasets;
        }
    },
    watch: {
        datasets(){
            this.chartHandler();
        },
    },
    methods: {
        jumpToDate,
        setCurrentQuestion,
        toggleDataset(index: number){
            const status = this.hiddenDatasets[index] = !this.hiddenDatasets[index];
            setCurrentQuestion();
            chart.setDatasetVisibility(index, !status);
            chart.update();
        },
        chartHandler(){
            if(chart.data){
                chart.data.labels = this.relevantDates;
                chart.data.datasets = this.datasets;
                return chart.update();
            }
            
            if(!this.$refs.chart)
                return;

            chart = new Chart(this.$refs.chart as HTMLCanvasElement, {
                type: 'line',
                data: { labels: this.relevantDates, datasets: this.datasets },
                options: {
                    animation: { duration: 0, onComplete: () => this.loaded = true },
                    scales:{
                        xAxis: {
                            type: 'time',
                            time: { displayFormats: { day: 'd/L' }, tooltipFormat: 'd/L/Y 🕒 kk:mm' }, 
                        },
                        yAxis: { ticks: { stepSize: 1 } }
                    },
                    plugins: {
                        tooltip: { enabled: false, external: this.externalTooltipHandler },
                        legend: { display: false },
                    },
                    onClick: (_, chartElements) => this.onChartClick(chartElements)      
                },
                plugins: [{ id: 'mouseMoveObserver', beforeEvent: (_, { event }) => mouseMoveObserver.next(event.native as Event) }] // Will trigger onChartMouseMove, with an auditTime of 100ms
            });
        },
        onChartClick(chartElements: ActiveElement[]){
            if(!chartElements?.length) return;
            const { datasetIndex, index } = chartElements[0];
            const { canvas, tooltip } = chart;

            canvas.style.cursor = 'default';

            setCurrentQuestion({
                datasetIndex,
                index,
                style: {
                    left: canvas.offsetLeft + tooltip!.caretX + 'px',
                    top: canvas.offsetTop + tooltip!.caretY + 'px',
                    color: tooltip!.labelColors[0].borderColor as string
                }
            });
        },
        onChartMouseMove(event: Event){
            const style = (event.target as HTMLCanvasElement)?.style;
            if(!style) return;

            // Change cursor to pointer if there are active elements on the chart, but not if active element is selected
            const activeElements = chart.getActiveElements?.();
            if(activeElements?.length && (!this.currentQuestion || (activeElements[0].datasetIndex !== this.currentQuestion.datasetIndex || activeElements[0].index !== this.currentQuestion.index))){
                if(style.cursor !== 'pointer')
                    style.cursor = 'pointer';
            }else if(style.cursor === 'pointer'){
                style.cursor = 'default';
            }
        },
        externalTooltipHandler({ chart, tooltip }: any){
            if(tooltip.opacity === 0) // Hide if no tooltip
                return this.tooltip.style.opacity = 0;

            Object.assign(this.tooltip, {
                style: {
                    opacity: 1,
                    left: chart.canvas.offsetLeft + tooltip.caretX + 'px',
                    top: chart.canvas.offsetTop + tooltip.caretY + 'px'
                },
                text: tooltip.title?.[0] ?? ''
            });
        }
    }
})
</script>
    
<style lang="scss" scoped>
    #chartWrapper {
        flex:1 ;
        padding: 1rem 2rem;
        height: 100%;
        overflow-y: auto;
        background-color: #fff;

        & > div {
            width: 1060px;
            margin: 0 auto;
            position: relative;

            & > h3 {
                margin-bottom: .5rem;
                font-weight: 100;
                letter-spacing: -1px;
            }
        }

        & canvas {
            margin: 0 auto;
            width: 100%;
        }
    }

    #chartLoading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.5rem;
        letter-spacing: .2rem;
        color: #ccc;
    }

    .chartLegend {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 0 2rem;
        flex-wrap: wrap;
        width: 90%;
        min-height: 6rem;
        margin: 1rem 0;
        font-size: .8rem;

        & > div {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
        & .chartLegendBullet {
            flex-shrink: 0;
            width: .9rem;
            height: .9rem;
            border-radius: 50%;
            margin-left: .5rem;
            border: 2px solid transparent;
            background-color: #eee;
        }
    }

    .chartTooltip {
        pointer-events: none;
        position: absolute;
        transform: translate(-50%, .5rem);
        transition: opacity .3s ease;
        padding: .5rem 1rem;
        width: max-content;
        font-size: .9rem;
    }

    .selectionIndicator {
        pointer-events: none;
        position: absolute;
        opacity: 0;
        transform: translate(-53%, -50%);
        background-color: #fff;
        height: 1.2rem;
        width: 1.2rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 .1rem .3rem #aaa;

        & .material-icons {
            font-size: 1.6rem;
            filter: brightness(120%);
        }

        &.hasSelection {
            opacity: 1;
        }
    }
</style>