
import { isMultiChoiceOrNum } from "@/helpers";
import { Response } from "@/ts/interfaces/Question";
import { defineComponent, PropType } from "@vue/runtime-core";
import { isEqual } from "lodash";

type data = {
    mainQuestion: string | null,
    tableActivated: boolean,
    highlighted: { row: { rowIndex: number } | null, column: { columnIndex: number, optionIndex: number } | null }
}

export default defineComponent({
    props: {
        relevantDates: { type: Array as PropType<string[]>, required: true }
    },
    data: (): data => ({
        mainQuestion: null as string | null,
        tableActivated: false,
        highlighted: { row: null, column: null }
    }),
    computed: {
        questions(){
            return Object.values(this.$store.state.viewQuestionnaire!.questions).filter(({ selected, type }) => selected && type === 'question');
        },
        data(){
            if(!this.tableActivated) return;

            const questions = this.$store.state.chooser!.questions;

            let columnCount = 1;
            let warnings: any[] = [];
            const columns = this.questions
                .map(({ id, path }) => {
                    const question = questions.find(q => q.id.toString() === id)!;

                    if(this.mainQuestion === path) return undefined;
                    if(!isMultiChoiceOrNum(question)){
                        warnings.push(question.text);
                        return undefined;
                    }
                    
                    columnCount += Object.values(question.options!).length;
                    return { path, text: question.text, options: question.options };
                })
                .filter(column => typeof column !== 'undefined');

            const responsesByDate = this.$store.state.viewQuestionnaire!.responsesByDate;
            const mainQuestion = questions.find(q => q.id.toString() === this.mainQuestion!.split('/').pop())!;
            const counter = Object.fromEntries(Object.keys(mainQuestion.options!).map(key => {
                return [key, {}] as [string | number, { [key: string]: { [key: number]: number } }];
            }));
            

            const totals = { all: 0 } as { [key: string]: number };

            const getPath = (path: string) => path.split('/').slice(1).join('/') // Removing questionnaire id from the path
            const handleValues = (values: Response['value'], forEachValue: any, debugData?: any) => {
                values = !Array.isArray(values) ? [values] : values;
                values.forEach(value => {
                    if(typeof value === 'string' && value !== 'na') value = 'other';
                    if(value !== null) forEachValue(value as number);
                    else console.error('Value was null', value, debugData)
                });
            }

            this.relevantDates.forEach(date => {
                const responsesOnDate = responsesByDate[date];
                const mainResponse = responsesOnDate[getPath(this.mainQuestion!)];

                if(!mainResponse?.value && mainResponse?.value !== 0) return;

                totals.all++;
                handleValues(mainResponse.value, (value: number) => {
                    const counterItem = counter[value];
                    totals[value] = (totals[value] ?? 0) + 1;
                    columns.forEach(column => {
                        const path = column!.path!;
                        const response = responsesOnDate[getPath(path)];
                        if(!response) return;

                        const optionCount = counterItem[path] = counterItem[path] ?? {};
                        handleValues(response.value, (value: number) => optionCount[value] = (optionCount[value] ?? 0) + 1, { date, handling: path });
                    });
                }, { date, handling: this.mainQuestion });
            });
            
            return { columns, columnCount, mainQuestion, counter, totals, warnings };
        }
    },
    watch: {
        mainQuestion(){
            this.highlighted = { row: null, column: null };
        }  
    },
    methods: {
        activateTable(){
            if(this.mainQuestion !== null)
                this.tableActivated = true;
        },
        highlight(highlightData: Exclude<data['highlighted']['row'] | data['highlighted']['column'], null>){
            if('rowIndex' in highlightData)
                this.highlighted.row = !isEqual(highlightData, this.highlighted.row) ? highlightData : null;
            else
                this.highlighted.column = !isEqual(highlightData, this.highlighted.column) ? highlightData : null;
        }
    }
});
