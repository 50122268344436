
/*
מידת שינוי של שאלות
*/


import { ComponentPublicInstance, defineComponent } from "@vue/runtime-core";
import { ViewQuestionnaireState } from "@/ts/state/ViewQuestionnaireState";
import CustomPopper, { popperOptions } from "@/CustomPopper";
import { setSelectionTooltip } from "../module";
import store from "@/store";
import { toggleChildren, toggleParent } from "./helpers";
import { dateToDashDelimited } from "@/helpers";

const tooltipWatcher = (ref: string, { placement = 'bottom-end', offset = { h: 0 } }: Partial<popperOptions> = {}) => {
    return function(this: ComponentPublicInstance<{}>, value: ViewQuestionnaireState['selectionTooltip'] | ViewQuestionnaireState['questionTooltip']){
        const tooltip = this.$refs[ref] as HTMLElement;
        if(value !== null)
            CustomPopper.Show({ button: value.anchorEl as HTMLElement, tooltip, placement, offset });
        else
            CustomPopper.Hide(tooltip);
    }
}

const selectionTooltipClickAway = (event: Event) => {
    const target = event.target as HTMLElement;
    if(store.state.viewQuestionnaire?.selectionTooltip && !target.closest('#selectionTooltip'))
        setSelectionTooltip()
}

export default defineComponent({
    mounted(){
        window.addEventListener('click', selectionTooltipClickAway);
    },
    beforeUnmount(){
        window.removeEventListener('click', selectionTooltipClickAway);
    },
    data: () => ({}),
    computed: {
        questionTree(){
            return this.$store.state.viewQuestionnaire?.questionTree ?? [];
        },
        dates(){
            return this.$store.state.viewQuestionnaire?.dates;
        },
        questionTooltip(){
            return this.$store.state.viewQuestionnaire?.questionTooltip;
        },
        selectionTooltip(){
            return this.$store.state.viewQuestionnaire?.selectionTooltip;
        },
        dateBoundaries(){
            if(!this.dates) return;

            const { all: dates } = this.dates;
            return {
                min: dateToDashDelimited(new Date(dates[0] ?? Date.now())),
                max: dateToDashDelimited(new Date(dates[dates.length - 1] ?? Date.now()))
            }
        },  
    },
    watch: {
        questionTooltip: tooltipWatcher('questionTooltip', { placement: 'left-start' }),
        selectionTooltip: tooltipWatcher('selectionTooltip', { placement: 'left-start', offset: { v: -2, h: 4 } })
    },
    methods: {
        toggleSelection(withChildren: boolean){
            const { node } = this.selectionTooltip!;
            node.selected = !node.selected;
            setSelectionTooltip();

            if(withChildren)
                toggleChildren(node.children, node.selected);
            toggleParent(node.parent, node.selected); // Order of toggleChildren and toggleParent is important here
        }
    }
});
