<template>
    <div v-if='!tableActivated' id='selectMainQuestion'>
        <div class='card'>
            <h1>{{ $parse('בחר', 'בחרי', 'בחרו') }} שאלה ראשית</h1>
            <ul>
                <li
                    v-for='question in questions'
                    :key='question.path'
                    :class='{ isSelected: mainQuestion === question.path }'
                    @click='mainQuestion = question.path'
                >
                    <span class='material-icons-round'>
                        {{ mainQuestion === question.path ? 'check' : 'horizontal_rule' }}
                    </span>
                    {{ $parse(question.text) }}
                </li>
            </ul>
            <button @click='activateTable'>המשך</button>
        </div>
    </div>
    <div v-else id='dataTable'>
        <div :style='{ gridTemplateColumns: `repeat(${data.columnCount}, 10rem)` }'>
            <div class='mainColumnHeader' :style='{ gridRowStart: "span 2" }'>
                <tooltip
                    v-if='data.warnings.length'
                    placement='bottom'
                    maxWidth='15.5rem'
                >
                    <template #default='slotProps'>
                        <span id='selectionWarning' class='material-icons-round' v-bind='slotProps'>warning</span>
                    </template>
                    <template #tooltip>
                        השאלות הבאות הן מסוג שלא מתאים לתצוגה בטבלה:
                        <ul id='selectionWarningList'>
                            <li v-for='warning of data.warnings' :key='warning'>{{ warning }}</li>
                        </ul>
                    </template>
                </tooltip>
                {{ $parse(data.mainQuestion.text) }}
            </div>
            <div
                v-for='column of data.columns'
                :key='column.path'
                class='columnGroupHeader'
                :style='{ gridColumnStart: `span ${column.options.length}` }'
            >
                {{ $parse(column.text) }}
            </div>
            <template v-for='(column, index) of data.columns' :key='column.path'>
                <div
                    v-for='(option, optionIndex) of column.options'
                    :key='optionIndex'
                    class='columnHeader'
                    @click='highlight({ columnIndex: index, optionIndex })'
                >
                    {{ $parse(option) }}
                </div>
            </template>
            <template v-for='(distribution, index) in data.counter' :key='index'>
                <div class='rowHeader' @click='highlight({ rowIndex: index })'>
                    {{ $parse(data.mainQuestion.options[index]) }} ({{ data.totals[index] ?? 0 }})
                </div>
                <template v-for='(column, columnIndex) of data.columns' :key='column.path'>
                    <div
                        v-for='(_, option) of column.options'
                        :key='option' class='tableCell'
                        :class='{ isHighlighted: (highlighted.column?.columnIndex === columnIndex && highlighted.column?.optionIndex === option) || highlighted.row?.rowIndex === index }'
                    >
                        {{ distribution[column.path]?.[option] ? Math.round(distribution[column.path][option] / (data.totals[index] / 100)) + '%' : '—' }}
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script lang='ts'>
import { isMultiChoiceOrNum } from "@/helpers";
import { Response } from "@/ts/interfaces/Question";
import { defineComponent, PropType } from "@vue/runtime-core";
import { isEqual } from "lodash";

type data = {
    mainQuestion: string | null,
    tableActivated: boolean,
    highlighted: { row: { rowIndex: number } | null, column: { columnIndex: number, optionIndex: number } | null }
}

export default defineComponent({
    props: {
        relevantDates: { type: Array as PropType<string[]>, required: true }
    },
    data: (): data => ({
        mainQuestion: null as string | null,
        tableActivated: false,
        highlighted: { row: null, column: null }
    }),
    computed: {
        questions(){
            return Object.values(this.$store.state.viewQuestionnaire!.questions).filter(({ selected, type }) => selected && type === 'question');
        },
        data(){
            if(!this.tableActivated) return;

            const questions = this.$store.state.chooser!.questions;

            let columnCount = 1;
            let warnings: any[] = [];
            const columns = this.questions
                .map(({ id, path }) => {
                    const question = questions.find(q => q.id.toString() === id)!;

                    if(this.mainQuestion === path) return undefined;
                    if(!isMultiChoiceOrNum(question)){
                        warnings.push(question.text);
                        return undefined;
                    }
                    
                    columnCount += Object.values(question.options!).length;
                    return { path, text: question.text, options: question.options };
                })
                .filter(column => typeof column !== 'undefined');

            const responsesByDate = this.$store.state.viewQuestionnaire!.responsesByDate;
            const mainQuestion = questions.find(q => q.id.toString() === this.mainQuestion!.split('/').pop())!;
            const counter = Object.fromEntries(Object.keys(mainQuestion.options!).map(key => {
                return [key, {}] as [string | number, { [key: string]: { [key: number]: number } }];
            }));
            

            const totals = { all: 0 } as { [key: string]: number };

            const getPath = (path: string) => path.split('/').slice(1).join('/') // Removing questionnaire id from the path
            const handleValues = (values: Response['value'], forEachValue: any, debugData?: any) => {
                values = !Array.isArray(values) ? [values] : values;
                values.forEach(value => {
                    if(typeof value === 'string' && value !== 'na') value = 'other';
                    if(value !== null) forEachValue(value as number);
                    else console.error('Value was null', value, debugData)
                });
            }

            this.relevantDates.forEach(date => {
                const responsesOnDate = responsesByDate[date];
                const mainResponse = responsesOnDate[getPath(this.mainQuestion!)];

                if(!mainResponse?.value && mainResponse?.value !== 0) return;

                totals.all++;
                handleValues(mainResponse.value, (value: number) => {
                    const counterItem = counter[value];
                    totals[value] = (totals[value] ?? 0) + 1;
                    columns.forEach(column => {
                        const path = column!.path!;
                        const response = responsesOnDate[getPath(path)];
                        if(!response) return;

                        const optionCount = counterItem[path] = counterItem[path] ?? {};
                        handleValues(response.value, (value: number) => optionCount[value] = (optionCount[value] ?? 0) + 1, { date, handling: path });
                    });
                }, { date, handling: this.mainQuestion });
            });
            
            return { columns, columnCount, mainQuestion, counter, totals, warnings };
        }
    },
    watch: {
        mainQuestion(){
            this.highlighted = { row: null, column: null };
        }  
    },
    methods: {
        activateTable(){
            if(this.mainQuestion !== null)
                this.tableActivated = true;
        },
        highlight(highlightData: Exclude<data['highlighted']['row'] | data['highlighted']['column'], null>){
            if('rowIndex' in highlightData)
                this.highlighted.row = !isEqual(highlightData, this.highlighted.row) ? highlightData : null;
            else
                this.highlighted.column = !isEqual(highlightData, this.highlighted.column) ? highlightData : null;
        }
    }
});
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_dataTable.scss";

#selectionWarningList {
    list-style: initial;
    padding: .5rem 1rem;
}

#selectMainQuestion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    & > div {
        width: 24rem;

        & button {
            padding: .8rem;
            border-top: $borderLight;
            background-color: $swatchC;
            width: 100%;

            &:hover {
                background-color: $hoverC;
            }
        }
    }

    & ul {
        padding: 1rem 1.5rem 1.5rem;
    }

    & li {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
        --currentColor: #ccc;
        
        & .material-icons-round {
            margin-left: .5rem;
            color: var(--currentColor);
            transform: translateY(.1rem);
        }

        &:hover, &.isSelected {
            --currentColor: #155788;
            color: var(--currentColor);
        }
    }
}
</style>