<template>
    <div id='questionSelection'>
        <div id='dateSelection'>
            <date-picker label='מתאריך' v-model='dates.start' v-bind='dateBoundaries' />
            <date-picker label='עד תאריך' v-model='dates.end' v-bind='dateBoundaries' />
        </div>
        <question-selection-item
            v-for='node in questionTree'
            :node='node'
            :key='node.id'
        />
        <teleport to='body'>
            <div id='questionTooltip' ref='questionTooltip' v-show='questionTooltip'>
                {{ questionTooltip?.text }}
                <div class='tooltipArrow' data-popper-arrow :style='{ color: "#333" }'></div>
            </div>
        </teleport>
        <div id='selectionTooltip' ref='selectionTooltip'>
            <div @click='toggleSelection(true)'>עם כל השאלות העוקבות</div>
            <div @click='toggleSelection(false)'>ללא שאלות עוקבות</div>
        </div>
    </div>
</template>

<script lang='ts'>
/*
מידת שינוי של שאלות
*/


import { ComponentPublicInstance, defineComponent } from "@vue/runtime-core";
import { ViewQuestionnaireState } from "@/ts/state/ViewQuestionnaireState";
import CustomPopper, { popperOptions } from "@/CustomPopper";
import { setSelectionTooltip } from "../module";
import store from "@/store";
import { toggleChildren, toggleParent } from "./helpers";
import { dateToDashDelimited } from "@/helpers";

const tooltipWatcher = (ref: string, { placement = 'bottom-end', offset = { h: 0 } }: Partial<popperOptions> = {}) => {
    return function(this: ComponentPublicInstance<{}>, value: ViewQuestionnaireState['selectionTooltip'] | ViewQuestionnaireState['questionTooltip']){
        const tooltip = this.$refs[ref] as HTMLElement;
        if(value !== null)
            CustomPopper.Show({ button: value.anchorEl as HTMLElement, tooltip, placement, offset });
        else
            CustomPopper.Hide(tooltip);
    }
}

const selectionTooltipClickAway = (event: Event) => {
    const target = event.target as HTMLElement;
    if(store.state.viewQuestionnaire?.selectionTooltip && !target.closest('#selectionTooltip'))
        setSelectionTooltip()
}

export default defineComponent({
    mounted(){
        window.addEventListener('click', selectionTooltipClickAway);
    },
    beforeUnmount(){
        window.removeEventListener('click', selectionTooltipClickAway);
    },
    data: () => ({}),
    computed: {
        questionTree(){
            return this.$store.state.viewQuestionnaire?.questionTree ?? [];
        },
        dates(){
            return this.$store.state.viewQuestionnaire?.dates;
        },
        questionTooltip(){
            return this.$store.state.viewQuestionnaire?.questionTooltip;
        },
        selectionTooltip(){
            return this.$store.state.viewQuestionnaire?.selectionTooltip;
        },
        dateBoundaries(){
            if(!this.dates) return;

            const { all: dates } = this.dates;
            return {
                min: dateToDashDelimited(new Date(dates[0] ?? Date.now())),
                max: dateToDashDelimited(new Date(dates[dates.length - 1] ?? Date.now()))
            }
        },  
    },
    watch: {
        questionTooltip: tooltipWatcher('questionTooltip', { placement: 'left-start' }),
        selectionTooltip: tooltipWatcher('selectionTooltip', { placement: 'left-start', offset: { v: -2, h: 4 } })
    },
    methods: {
        toggleSelection(withChildren: boolean){
            const { node } = this.selectionTooltip!;
            node.selected = !node.selected;
            setSelectionTooltip();

            if(withChildren)
                toggleChildren(node.children, node.selected);
            toggleParent(node.parent, node.selected); // Order of toggleChildren and toggleParent is important here
        }
    }
});
</script>

<style lang="scss" scoped>
#questionSelection {
    background-color: rgba(#fff, .7);
    width: var(--questionSelectionWidth);
    border-left: 1px solid #eee;
    padding: 0 1rem 1rem;
    height: 100%;
    overflow-y: scroll;
    position: relative;
    flex-shrink: 0;
}

#dateSelection {
    display: flex;
    justify-content: center;
    gap: 3rem;
    padding: 1.2rem 0;
    border-bottom: 1px solid #ccc;
    background-color: #fff;
    margin-bottom: 1rem;
    position: sticky;
    top: 0;
    z-index: 30;
}

#questionTooltip {
    position: absolute;
    padding: .3rem .6rem;
    background-color: #333;
    color: #eee;
    border-radius: $corner;
    max-width: 18rem;
    text-align: center;
    z-index: 10;
    display: none;

    &[data-show] {
        opacity: 0;
        display: block;
        animation: fade-in ease-in .15s forwards;
	}

    & .tooltipArrow::before {
        right: 0px !important;
    }
}

#selectionTooltip {
    font-size: .7rem;
    background-color: #fff;
    z-index: 20;
    border-radius: $corner;
    border: 1px solid #eee;
    overflow: hidden;
    @include shadow;

    &:not([data-show]){
        display: none;
    }

    & > div {
        cursor: pointer;
        padding: .2rem .5rem .2rem 1.2rem;

        &:first-child {
            border-bottom: inherit;
        }

        &:hover {
            background-color: $hoverC;
        }
    }
}
</style>