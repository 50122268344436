
import { defineComponent } from "@vue/runtime-core";
import 'chartjs-adapter-date-fns';
// @ts-ignore
import module, { loadData } from './module';

import QuestionSelection from './QuestionSelection/index.vue';
import LineChart from "./LineChart.vue";
import ComparisonTable from "./ComparisonTable.vue";
import InitialView from "./InitialView.vue";
import { addDays, isAfter, isBefore } from "date-fns";

export default defineComponent({
    components: { QuestionSelection , LineChart, ComparisonTable, InitialView },
    async beforeMount(){
        this.$store.registerIfDoesNotExist('viewQuestionnaire', module);
        await loadData();
    },
    data: () => ({ chartMode: 'initial-view' as 'initial-view' | 'line-chart' | 'comparison-table' }),
    computed: {
        relevantDates(){
            const dates = this.$store.state.viewQuestionnaire?.dates;
            return dates?.all.filter((dateString: string) => {
                const date = new Date(dateString);
                return isAfter(date, addDays(dates.start as Date, -1)) && isBefore(date, addDays(dates.end as Date, 1));
            }) ?? [];
        }
    }
})
