<template>
    <teleport to='body'>
        <div v-draggable='{ cancel: ".closeDetails" }' id='chartDetails' class='card' v-if='currentQuestion'>
            <div id='detailsTitle'>
                <h3>{{ selectionTexts.title.date }}</h3>
                <h4>
                    <span class='material-icons-round'>
                        schedule
                    </span>
                    {{ selectionTexts.title.time }}
                </h4>
                <span class='material-icons-round closeDetails' @click='setCurrentQuestion()'>close</span>
            </div>
            <ul>
                <li v-for='item in selectionTexts.items' :key='item.id'>
                    <span class='customChartBullet' :style='{ borderColor: item.borderColor }' />
                    <div>
                        <div>{{ item.question }}</div>
                        <div>{{ item.response }}</div>
                    </div>
                </li>
            </ul>
        </div>
    </teleport>
</template>

<script lang="ts">
import { SharedChartDataset } from "@/ts/state/ViewQuestionnaireState";
import { defineComponent, PropType } from "@vue/runtime-core";

import { translateMonth } from "@/helpers/dates";
import { format } from "date-fns/esm";
import { setCurrentQuestion } from "./module";

export default defineComponent({
    props: {
        datasets: { type: Array as PropType<SharedChartDataset[]>, required: true },
        relevantDates: { type: Array as PropType<string[]>, required: true }
    },
    computed: {
        currentQuestion(){
            return this.$store.state.viewQuestionnaire?.currentQuestion;
        },
        responsesByDate(){
            return this.$store.state.viewQuestionnaire?.responsesByDate;
        },
        selectionTexts(){
            const { currentQuestion, datasets, relevantDates } = this;
            if(!currentQuestion) return;
            
            const { questions, responsesByDate } = this.$store.state.viewQuestionnaire!;
            const rawDate = relevantDates[currentQuestion.index];
            const responses = responsesByDate[rawDate];
            const date = new Date(rawDate);
 
            return {
                title: { date: translateMonth(format(date, 'd בLLL yyy')), time: format(date, 'HH:mm') },
                items: Object.values(questions).filter(({ selected, type }) => selected && type === 'question').map(({ id, path }) => {
                    const question = this.$store.state.chooser!.questions.find(q => q.id.toString() === id)!;
                    const dataset = datasets.find(dataset => dataset.id === path);
                    const responseObj = responses[path!.split('/').slice(1).join('/')];
                    const response = responseObj
                        ? !Array.isArray(responseObj.value)
                            ? this.$parse(question.options![responseObj.value as number])
                            : responseObj.value.map(value => this.$parse(question.options![value as number])).join(', ')
                        : '—';

                    return {
                        question: dataset ? dataset.label as string : this.$parse(question.text),
                        id,
                        borderColor: dataset ? dataset.borderColor as string : '#999',
                        response
                    }
                })
            };
        }
    },
    methods: {
        setCurrentQuestion
    }
})
</script>

<style lang="scss" scoped>
    #chartDetails {
        position: absolute !important;
        right: 2vw;
        top: 4vw;
        border: 1px solid #eee;
        width: 20rem;
        height: fit-content;
        max-height: 70vh;
        overflow: auto;
        padding: 0 1rem 1rem;
        position: relative;
        z-index: 50;

        cursor: grab;

        &:active {
            cursor: grabbing;
        }

        & #detailsTitle {
            padding: 1rem 0;
            position: sticky;
            top: 0;
            z-index: 50;
            background-color: #fff;

            & .closeDetails {
                position: absolute;
                top: .4rem;
                right: -.4rem;
                font-size: 1.1rem;
                opacity: .5;
                user-select: none;
                z-index: 51;

                &:hover {
                    opacity: 1;
                    scale: 1.1;
                    cursor: pointer;
                }
            }

            & > h4 {
                color: #999;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                width: fit-content;
                margin: 0 auto;

                & > span {
                    position: absolute;
                    right: -1.5em;
                    font-size: 1.2em;
                }
            }
        }

        & ul {
            list-style: none;
        }

        & li {
            display: flex;
            font-size: 0.8rem;

            &:not(:last-child) {
                margin-bottom: .8rem;
            }

            & > div {
                & > :first-child {
                    font-weight: 500;
                }

                & > :last-child {
                    color: #777;
                }
            }
        }
    }
</style>