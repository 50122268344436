
import { defineComponent, PropType } from "@vue/runtime-core";
import Chart, { ActiveElement } from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
// @ts-ignore
import { auditTime, filter, Subject, Subscription } from 'rxjs';
import { setCurrentQuestion, jumpToDate } from './module';

import ChartDetails from "./ChartDetails.vue";
import { SharedChartDataset } from "@/ts/state/ViewQuestionnaireState";

let chart: Chart = {} as Chart;
const mouseMoveObserver = new Subject<Event>();
let subscription = {} as Subscription;

type data = {
    tooltip: { style: { [key: string]: string | number }, text: string },
    hiddenDatasets: { [key: number]: boolean },
    loaded: boolean
}

const colors = ['#2b225c', '#6b276c', '#a62a6b', '#d73e5c', '#f76543', '#ff971d', '#f5cb00', '#cfff25'];
const getColor = (index: number) => colors[index < colors.length ? index : index - (Math.floor(index / colors.length) * colors.length)];

export default defineComponent({
    props: {
        relevantDates: { type: Array as PropType<string[]>, required: true }
    },
    components: { ChartDetails },
    async beforeMount(){       
        subscription = mouseMoveObserver.pipe(
            auditTime(100),
            filter((event: Event) => event?.type === 'mousemove')
        ).subscribe((event: Event) => this.onChartMouseMove(event));
    },
    mounted(){
        this.chartHandler();
    },
    beforeUnmount: () => {
        subscription.unsubscribe?.();
        chart.destroy?.();
        chart = {} as Chart;
        setCurrentQuestion();
    },
    data: (): data => ({
        tooltip: { style: { opacity: 0 }, text: '' },
        hiddenDatasets: {},
        loaded: false
    }),
    computed: {
        currentQuestion(){
            return this.$store.state.viewQuestionnaire?.currentQuestion;
        },
        datasets(){
            const { questions, responsesById, responsesByDate } = this.$store.state.viewQuestionnaire ?? {};
            const { relevantDates } = this;
            const datasets: SharedChartDataset[] = [];

            for(const path in questions){
                const node = questions[path];
                if(node.selected && responsesById?.[node.id!]){
                    const question = this.$store.state.chooser!.questions.find(q => q.id.toString() === node.id);
                    if(question?.type?.startsWith('radio')){
                        const color = getColor(datasets.length);
                        datasets.push({
                            id: node.path!,
                            label: this.$parse(question.text),
                            data: this.relevantDates.map((date: string) => {
                                const response = responsesByDate![date][node.path!.split('/').slice(1).join('/')];
                                return response?.value || response?.value === 0 ? parseInt(response.value as string) + 1 : 0;
                            }),
                            fill: false,
                            showLine: question.type === 'radioContinuous',
                            borderColor: color,
                            borderWidth: 2,
                            tension: 0.1,
                            pointBackgroundColor: question.type !== 'radioContinuous' ? color : 'transparent',
                            pointHitRadius: relevantDates.length < 50 ? 12 : 6,
                            pointRadius: relevantDates.length < 50 ? 4 : 3,
                            pointHoverRadius: relevantDates.length < 50 ? 6 : 5
                        })
                    }
                }
            }

            return datasets;
        }
    },
    watch: {
        datasets(){
            this.chartHandler();
        },
    },
    methods: {
        jumpToDate,
        setCurrentQuestion,
        toggleDataset(index: number){
            const status = this.hiddenDatasets[index] = !this.hiddenDatasets[index];
            setCurrentQuestion();
            chart.setDatasetVisibility(index, !status);
            chart.update();
        },
        chartHandler(){
            if(chart.data){
                chart.data.labels = this.relevantDates;
                chart.data.datasets = this.datasets;
                return chart.update();
            }
            
            if(!this.$refs.chart)
                return;

            chart = new Chart(this.$refs.chart as HTMLCanvasElement, {
                type: 'line',
                data: { labels: this.relevantDates, datasets: this.datasets },
                options: {
                    animation: { duration: 0, onComplete: () => this.loaded = true },
                    scales:{
                        xAxis: {
                            type: 'time',
                            time: { displayFormats: { day: 'd/L' }, tooltipFormat: 'd/L/Y 🕒 kk:mm' }, 
                        },
                        yAxis: { ticks: { stepSize: 1 } }
                    },
                    plugins: {
                        tooltip: { enabled: false, external: this.externalTooltipHandler },
                        legend: { display: false },
                    },
                    onClick: (_, chartElements) => this.onChartClick(chartElements)      
                },
                plugins: [{ id: 'mouseMoveObserver', beforeEvent: (_, { event }) => mouseMoveObserver.next(event.native as Event) }] // Will trigger onChartMouseMove, with an auditTime of 100ms
            });
        },
        onChartClick(chartElements: ActiveElement[]){
            if(!chartElements?.length) return;
            const { datasetIndex, index } = chartElements[0];
            const { canvas, tooltip } = chart;

            canvas.style.cursor = 'default';

            setCurrentQuestion({
                datasetIndex,
                index,
                style: {
                    left: canvas.offsetLeft + tooltip!.caretX + 'px',
                    top: canvas.offsetTop + tooltip!.caretY + 'px',
                    color: tooltip!.labelColors[0].borderColor as string
                }
            });
        },
        onChartMouseMove(event: Event){
            const style = (event.target as HTMLCanvasElement)?.style;
            if(!style) return;

            // Change cursor to pointer if there are active elements on the chart, but not if active element is selected
            const activeElements = chart.getActiveElements?.();
            if(activeElements?.length && (!this.currentQuestion || (activeElements[0].datasetIndex !== this.currentQuestion.datasetIndex || activeElements[0].index !== this.currentQuestion.index))){
                if(style.cursor !== 'pointer')
                    style.cursor = 'pointer';
            }else if(style.cursor === 'pointer'){
                style.cursor = 'default';
            }
        },
        externalTooltipHandler({ chart, tooltip }: any){
            if(tooltip.opacity === 0) // Hide if no tooltip
                return this.tooltip.style.opacity = 0;

            Object.assign(this.tooltip, {
                style: {
                    opacity: 1,
                    left: chart.canvas.offsetLeft + tooltip.caretX + 'px',
                    top: chart.canvas.offsetTop + tooltip.caretY + 'px'
                },
                text: tooltip.title?.[0] ?? ''
            });
        }
    }
})
