
import { SharedChartDataset } from "@/ts/state/ViewQuestionnaireState";
import { defineComponent, PropType } from "@vue/runtime-core";

import { translateMonth } from "@/helpers/dates";
import { format } from "date-fns/esm";
import { setCurrentQuestion } from "./module";

export default defineComponent({
    props: {
        datasets: { type: Array as PropType<SharedChartDataset[]>, required: true },
        relevantDates: { type: Array as PropType<string[]>, required: true }
    },
    computed: {
        currentQuestion(){
            return this.$store.state.viewQuestionnaire?.currentQuestion;
        },
        responsesByDate(){
            return this.$store.state.viewQuestionnaire?.responsesByDate;
        },
        selectionTexts(){
            const { currentQuestion, datasets, relevantDates } = this;
            if(!currentQuestion) return;
            
            const { questions, responsesByDate } = this.$store.state.viewQuestionnaire!;
            const rawDate = relevantDates[currentQuestion.index];
            const responses = responsesByDate[rawDate];
            const date = new Date(rawDate);
 
            return {
                title: { date: translateMonth(format(date, 'd בLLL yyy')), time: format(date, 'HH:mm') },
                items: Object.values(questions).filter(({ selected, type }) => selected && type === 'question').map(({ id, path }) => {
                    const question = this.$store.state.chooser!.questions.find(q => q.id.toString() === id)!;
                    const dataset = datasets.find(dataset => dataset.id === path);
                    const responseObj = responses[path!.split('/').slice(1).join('/')];
                    const response = responseObj
                        ? !Array.isArray(responseObj.value)
                            ? this.$parse(question.options![responseObj.value as number])
                            : responseObj.value.map(value => this.$parse(question.options![value as number])).join(', ')
                        : '—';

                    return {
                        question: dataset ? dataset.label as string : this.$parse(question.text),
                        id,
                        borderColor: dataset ? dataset.borderColor as string : '#999',
                        response
                    }
                })
            };
        }
    },
    methods: {
        setCurrentQuestion
    }
})
