<template>
    <div id='chartPage'>
        <question-selection />
        <teleport to='body' v-if='chartMode !== "initial-view"'>
            <div id='switchMode'>
                <button
                    class='material-icons-round'
                    :class='{ isActive: chartMode === "line-chart" }'
                    @click='chartMode="line-chart"'
                >
                    insights
                </button>
                <button
                    class='material-icons-round'
                    :class='{ isActive: chartMode === "comparison-table" }'
                    @click='chartMode="comparison-table"'
                >
                    table_chart
                </button>
            </div>
        </teleport>
        <component :is='chartMode' :relevantDates='relevantDates' @update:chartMode='v => chartMode = v' />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";
import 'chartjs-adapter-date-fns';
// @ts-ignore
import module, { loadData } from './module';

import QuestionSelection from './QuestionSelection/index.vue';
import LineChart from "./LineChart.vue";
import ComparisonTable from "./ComparisonTable.vue";
import InitialView from "./InitialView.vue";
import { addDays, isAfter, isBefore } from "date-fns";

export default defineComponent({
    components: { QuestionSelection , LineChart, ComparisonTable, InitialView },
    async beforeMount(){
        this.$store.registerIfDoesNotExist('viewQuestionnaire', module);
        await loadData();
    },
    data: () => ({ chartMode: 'initial-view' as 'initial-view' | 'line-chart' | 'comparison-table' }),
    computed: {
        relevantDates(){
            const dates = this.$store.state.viewQuestionnaire?.dates;
            return dates?.all.filter((dateString: string) => {
                const date = new Date(dateString);
                return isAfter(date, addDays(dates.start as Date, -1)) && isBefore(date, addDays(dates.end as Date, 1));
            }) ?? [];
        }
    }
})
</script>

<style lang="scss">
    :root {
        --questionSelectionWidth: 25rem;
    }

    .customChartBullet {
        margin-left: 0.75rem;
        width: 1rem;
        height: 1rem;
        border: 2px solid #aaa;
        border-radius: 0.3rem !important;
        flex-shrink: 0;
        transform: translateY(.1rem);

        &.isSelected {
            background-color: $swatchA;
        }
    }
</style>
    
<style lang="scss" scoped>
    #chartPage {
        display: flex;
        width: 100%;
        height: calc(100vh - 64px);
        position: relative;
    }
    #switchMode {
        position: absolute;
        left: calc(50% - (var(--questionSelectionWidth) / 2));
        top: 2rem;
        padding: .5rem;
        background-color: rgba(#fff, .6);
        @include shadow;
        border-radius: $corner;
        transform: translate(-50%);
        display: flex;
        gap: .4rem;
        z-index: 4;

        & .material-icons-round {
            opacity: .5;
            
            &:not(.isActive) {
                cursor: pointer;
            }

            &:hover, &.isActive {
                opacity: 1;
                scale: 1.15;
            }
        }
    }
</style>